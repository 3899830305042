import App from 'next/app'
import router from 'next/router'
import { ToastProvider } from 'react-toast-notifications'
import AuthProvider from 'components/AuthProvider'
import { init } from 'lib/sentry'

import 'styles/global.css'

import axios from 'axios'

init()

axios.interceptors.response.use(
  function (response) {
    console.log('✅', response)
    return response
  },
  function (error) {
    console.log('😡', error)

    if (error.response.status === 401 && error.response?.data?.message === 'session expired') {
      router.push('/login')
    }

    return Promise.reject(error)
  }
)

// Sentry.init({
//   dsn: process.env.SENTRY_DSN,
//   integrations: [new Integrations.BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// })

const MyApp = ({ Component, pageProps, err }) => {
  return (
    <AuthProvider>
      <ToastProvider>
        <Component {...pageProps} err={err} />
      </ToastProvider>
    </AuthProvider>
  )
}

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext)

  return { ...appProps }
}

export default MyApp
